import InitDashboardGeneralPage from "../../dashboard/page/InitDashboardGeneralPage";

const InitInformasiPage: React.FC = () => {
    return (
        <div>
            Informasi
        </div>
    );
}

export default InitInformasiPage;