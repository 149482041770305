
import { useLocation } from 'react-router-dom';
export const KEY = "_key";
export const IDS = "_urlIds";

export function myUrl(): string {
    const protocol = window.location.protocol.replace(':','://');
    const port = window.location.port==''?'':':'+window.location.port;
    return protocol+window.location.hostname+port+"/";
}

export function paramValueOf(key:string): string {
    const currentUrl = window.location.href;
    const params = new URLSearchParams(currentUrl.split('?')[1]);
    let value = params.get('_key') as string;
    if (value) {
        value = value.replace(/ /g, '+');
    }
    return value;
}

