import {useEffect, useState} from 'react';
import {handleRegister} from "../service/AuthGeneralService";
import { useNavigate } from 'react-router-dom';
import "../../../../shared/component/css/main-style.css";
import "../css/login.css";
// @ts-ignore
import splashUp from "../logo/splash-up.png";
// @ts-ignore
import splashDown from "../logo/splash-down.png";
// @ts-ignore
import fogotPassword from "../logo/forgot-password-img.png";
// @ts-ignore
import presonRd from "../logo/person-rd.png";
// @ts-ignore
import presonGr from "../logo/person-gr.png";
// @ts-ignore
import lockGr from "../logo/lock-gr.png";
// @ts-ignore
import lockRd from "../logo/lock-rd.png";
// @ts-ignore
import eyeGr from "../logo/eye-gr.png";
// @ts-ignore
import eyeCloseGr from "../logo/eye-close-gr.png";
// @ts-ignore
import bilanLogo from "../../../../shared/component/logo/bilan-logo.png";
import Loading from "../../../../shared/component/particel/Loading";
// @ts-ignore
import warningRd from "../logo/warning-rd.png";
// @ts-ignore
import registrasi from "../logo/registrasi.png";
// @ts-ignore
import information from "../logo/information.png";
// @ts-ignore
import forgotPass from "../logo/forgot-password.png";
// @ts-ignore
import doorLogo from "../logo/door.png";
// @ts-ignore
import registrasiImg from "../logo/registrasi-img.png";
// @ts-ignore
import emailGr from "../logo/email-gr.png";
// @ts-ignore
import phoneGr from "../logo/phone-gr.png";
// @ts-ignore
import emailRd from "../logo/email-rd.png";
// @ts-ignore
import phoneRd from "../logo/phone-rd.png";
// @ts-ignore
import checklist from "../logo/checklist.png";


// @ts-ignore
import ErrorMessage from "../../../../shared/component/particel/ErrorMessage";
import SuccessMessage from "../../../../shared/component/particel/SuccessMessage";
import {myUrl} from "../../../../shared/component/script/UtilService";


const InitRegistrasiGeneralPage: React.FC = () => {

    const [registReq, setRegiestReq] = useState({ username: '', phoneNumber: '',email:'', domain:''});
    const [isUsernameFocused, setIsUsernameFocused] = useState(false);
    const [isPhoneNumberFocused, setIsPhoneNumberFocused] = useState(false);
    const [isEmailFocused, setIsEmailFocused] = useState(false);
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError,setIsError] = useState(false);
    const [isSuccess,setIsSuccess] = useState(false);
    const [messageError,setMessageError] = useState('');
    const [messageSuccess,setMessageSuccess] = useState('');
    const [isMaskedText,setIsMaskedText]=useState(true);

    const handleUserNameFocus = () => setIsUsernameFocused(true);
    const handleUserNameBlur = () => setIsUsernameFocused(false);
    const handlePhoneNumberFocus = () => setIsPhoneNumberFocused(true);
    const handlePhoneNumberBlur = () => setIsPhoneNumberFocused(false);
    const handleEmailFocus = () => setIsEmailFocused(true);
    const handleEmailBlur = () => setIsEmailFocused(false);
    const handleLoading=(loading:boolean)=>setIsLoading(loading);
    const handleError=(isError:boolean,messageError:string)=>{
        setIsError(isError);
        setMessageError(messageError);
    }
    const handleSuccess=(isSuccess:boolean,messageSuccess:string)=>{
        setIsSuccess(isSuccess);
        setMessageSuccess(messageSuccess);
    }

    const navigate = useNavigate();

    useEffect(()=>{
        setRegiestReq({ ...registReq, domain: myUrl() });
    },[]);

    return (
        <div className="container">
            <div className="content">

                <Loading isLoading={isLoading}/>

                <div className="splash-up">
                    <img className="splash-images" src={splashUp}  />
                </div>

                <div className="box-field login">
                    <div className="login-container">
                        <div className="box-obstacle">
                            <ErrorMessage isError={isError} messageError={messageError} image={warningRd}/>
                            <SuccessMessage isSuccess={isSuccess} messageSuccess={messageSuccess} image={checklist}/>

                            <div className="logo-login">
                                <img className="logo" src={registrasiImg}  />
                            </div>
                            <div className="display-form-login">
                                <div className={(!isUsernameFocused && registReq.username=='')?"container-input username":
                                    (isError?"container-input username red": "container-input username focus")}>
                                    {isError?<img src={presonRd} className="icon-logo"/>:<img src={presonGr} className="icon-logo"/>}
                                    <input
                                        autoComplete="email"
                                        className="input-form"
                                        type="text"
                                        placeholder="Username"
                                        value={registReq.username}
                                        onBlur={handleUserNameBlur}
                                        onFocus={handleUserNameFocus}
                                        onChange={(e) => {
                                            setIsError(false);
                                            const regex = /^[a-zA-Z0-9]*$/;
                                            if(!regex.test(e.target.value)){
                                                return;
                                            }
                                            setRegiestReq({ ...registReq, username: e.target.value })
                                        }}
                                    />
                                    { isError?
                                        <img src={warningRd} className="icon-logo-br warning"/>:''
                                    }
                                </div>

                                <div className={(!isPhoneNumberFocused && registReq.phoneNumber=='')?"container-input username":
                                    (isError?"container-input username red": "container-input username focus")}>
                                    {isError?<img src={phoneRd} className="icon-logo"/>:<img src={phoneGr} className="icon-logo"/>}
                                    <input
                                        autoComplete="email"
                                        className="input-form"
                                        type="text"
                                        placeholder="Phone number (+62)"
                                        value={registReq.phoneNumber}
                                        onBlur={handlePhoneNumberBlur}
                                        onFocus={handlePhoneNumberFocus}
                                        maxLength={14}
                                        onChange={(e) => {
                                            setIsError(false);

                                            if(e.target.value.substring(0,3)!=='+62'){
                                                e.target.value = '+62'+e.target.value;
                                            }
                                            const regex = /^\+62\d*$/;
                                            if(!regex.test(e.target.value) && e.target.value!=''){
                                                return;
                                            }

                                            setRegiestReq({ ...registReq, phoneNumber: e.target.value })
                                        }}
                                    />
                                    { isError?
                                        <img src={warningRd} className="icon-logo-br warning"/>:''
                                    }
                                </div>

                                <div className={(!isEmailFocused && registReq.email=='')?"container-input username":
                                    (isError?"container-input username red": "container-input username focus")}>
                                    {isError?<img src={emailRd} className="icon-logo"/>:<img src={emailGr} className="icon-logo"/>}
                                    <input
                                        autoComplete="email"
                                        className="input-form"
                                        type="text"
                                        placeholder="Email"
                                        value={registReq.email}
                                        onBlur={handleEmailBlur}
                                        onFocus={handleEmailFocus}
                                        onChange={(e) => {
                                            setIsError(false);
                                            setRegiestReq({ ...registReq, email: e.target.value })
                                        }}
                                    />
                                    { isError?
                                        <img src={warningRd} className="icon-logo-br warning"/>:''
                                    }
                                </div>


                                <div className="footer-form">
                                    <div className="contact-me"> </div>
                                    <div className="powered-by"> <span className="powered-by-span"> powered by : </span>  <img className="bilan-logo" src={bilanLogo}  /></div>
                                </div>

                                <button
                                    disabled={(registReq.username=='' ||  registReq.email=='' || registReq.phoneNumber=='')}
                                    className={(registReq.username=='' ||   registReq.email=='' || registReq.phoneNumber=='')?"button-form":
                                        isButtonActive?
                                            "button-form focus" : "button-form focus active"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleRegister(registReq,navigate,handleLoading,handleError,handleSuccess);
                                    }}>Registrasi</button>

                                <div className="additional-form">
                                    <div className="registrasi" onClick={()=>navigate("/init")}>
                                        <img src={doorLogo} />
                                    </div>
                                    <div className="information">
                                        <img src={information} onClick={()=>navigate("/information")}/>
                                    </div>
                                    <div className="lupa-password">
                                        <img src={forgotPass} onClick={()=>navigate("/forgot-password")}/>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


                <div className="splash-down">
                    <img className="splash-images" src={splashDown}  />
                </div>

            </div>
        </div>
    );
};

export default InitRegistrasiGeneralPage;