import "../css/errorLoading.css";

interface LoadingSpinnerProps {
    isError: boolean;
    messageError:string;
    image:string;
}

const ErrorMessage : React.FC<LoadingSpinnerProps> = ({ image,isError,messageError }) => {
    return (
        <div>
            {
                isError?
                    <div className="message-box error-box in">
                        <img src={image} className="icon-logo-br warning"/>
                        <span className="value-span">{messageError}.</span>
                    </div>:
                    <div className="message-box error-box out">
                        <img src={image} className="icon-logo-br warning"/>
                        <span className="value-span">{messageError}.</span>
                    </div>
            }
        </div>

    );
};

export default ErrorMessage;