import {ResponseTemplate} from "../../dto/ResponseTemplate";
import {REACT_APP_SUCCESS_CODE} from "../../constant/GlobalVariable";

//TEST

export const isStatusResponseOk = (response:ResponseTemplate<any>) => {

    var responseSchema = response.responseSchema;
    var successCode = REACT_APP_SUCCESS_CODE;
    return responseSchema.responseCode===successCode;
}

export const checkMessageResponse = (response:ResponseTemplate<any>) => {
    return response.responseSchema.responseMessage;
}

export const checkCodeResponse = (response:ResponseTemplate<any>) => {
    return response.responseSchema.responseCode;
}

export const checkOutputResponse = (response:ResponseTemplate<any>) => {
    return response.responseOutput;
}