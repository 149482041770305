import {isStatusResponseOk} from "../../../../shared/component/script/AxiosService";
import {LoginReq} from "../dto/LoginReq";
import {LoginResp} from "../dto/LoginResp";
import {
    callBlokedVerif,
    callCaptcha,
    callFinalVerifRegist,
    callLoginUser,
    callRegister
} from "../external/LoginExternal";
import {ResponseTemplate} from "../../../../shared/dto/ResponseTemplate";
import {REACT_APP_SUCCESS_CODE} from "../../../../shared/constant/GlobalVariable";
import {RegisterReq} from "../dto/RegisterReq";
import {VerifReq} from "../dto/VerifReq";

export async function loginGeneral(loginReq:LoginReq,loginIds:string): Promise<ResponseTemplate<LoginResp>> {
    try {
        const loginResp:ResponseTemplate<LoginResp> = await callLoginUser(loginIds, loginReq);
        if(isStatusResponseOk(loginResp)) {
            setLocalStorage(loginResp);
        }
        return loginResp;
    } catch (error) {
        alert('error on loginGeneral : '+ error)
        console.error('error on loginGeneral',error);
        throw new Error('Something went wrong! : '+ error);
    }
}

export async function registerGeneral(registReq:RegisterReq,registIds:string): Promise<ResponseTemplate<undefined>> {
    try {
        const response:ResponseTemplate<undefined> = await callRegister(registIds, registReq);
        return response;
    } catch (error) {
        alert('error on loginGeneral : '+ error)
        console.error('error on loginGeneral',error);
        throw new Error('Something went wrong! : '+ error);
    }
}

export async function createCaptha(verifReq:VerifReq,registIds:string): Promise<Blob> {
    try {
        const response:Blob = await callCaptcha(registIds, verifReq);
        return response;
    } catch (error) {
        alert('error on loginGeneral : '+ error)
        console.error('error on loginGeneral',error);
        throw new Error('Something went wrong! : '+ error);
    }
}

export async function verifRegist(verifReq:VerifReq,registIds:string,
                                  navigate: (path: string) => void,
                                  setLoading: (isLoading: boolean) => void,
                                  setErrorHandle: (isError: boolean,messageError:string) => void,
                                  setSuccessHandle: (isSuccess: boolean,messageSuccess:string) => void): Promise<void> {
    setLoading(true);
    try {
        const response:ResponseTemplate<undefined> = await callFinalVerifRegist(verifReq,registIds);
        if (response.responseSchema.responseCode === REACT_APP_SUCCESS_CODE) {
            setSuccessHandle(true,"Selamat yah sobat, verifikasi berhasil, silahkan buka email kamu lalu login menggunakan username dan password yang telah diberikan pada email tersebut.");
            setTimeout(() => {
                navigate('/');
            }, 6000);

        }else {
            setErrorHandle(true,response.responseSchema.responseMessage)
        }
        setLoading(false);
    } catch (error) {
        alert('error on loginGeneral : '+ error)
        console.error('error on loginGeneral',error);
        throw new Error('Something went wrong! : '+ error);
    }
}

export async function blockedVerif(verifReq:VerifReq,registIds:string,
                                  navigate: (path: string) => void,
                                  setLoading: (isLoading: boolean) => void,
                                  setErrorHandle: (isError: boolean,messageError:string) => void,
                                  setSuccessHandle: (isSuccess: boolean,messageSuccess:string) => void): Promise<void> {
    setLoading(true);
    try {
        const response:ResponseTemplate<undefined> = await callBlokedVerif(verifReq,registIds);
        if (response.responseSchema.responseCode === REACT_APP_SUCCESS_CODE) {
            setSuccessHandle(true,"Tenang, semua dapat diatasi. Coba nikmati kembali yah fitur kami sahabat.");
            setTimeout(() => {
                navigate('/');
            }, 6000);

        }else {
            setErrorHandle(true,response.responseSchema.responseMessage)
        }
        setLoading(false);
    } catch (error) {
        alert('error on loginGeneral : '+ error)
        console.error('error on loginGeneral',error);
        throw new Error('Something went wrong! : '+ error);
    }
}

export const handleLogin = async (loginReq: LoginReq,
                                  navigate: (path: string) => void,
                                  setLoading: (isLoading: boolean) => void,
                                  setErrorHandle: (isError: boolean,messageError:string) => void): Promise<void> => {

    if(loginReq.username!='' &&  loginReq.password!='') {
        setLoading(true);
        const loginResp: ResponseTemplate<LoginResp> = await loginGeneral(loginReq, 'general');
        setLoading(false);
        if (loginResp.responseSchema.responseCode === REACT_APP_SUCCESS_CODE) {
            navigate('/dashboard');
        }else {
            setErrorHandle(true,loginResp.responseSchema.responseMessage)
        }
    }
};

export const handleRegister = async (registerReq: RegisterReq,
                                  navigate: (path: string) => void,
                                  setLoading: (isLoading: boolean) => void,
                                  setErrorHandle: (isError: boolean,messageError:string) => void,
                                  setSuccessHandle: (isSuccess: boolean,messageSuccess:string) => void): Promise<void> => {

    if(registerReq.username!='' &&  registerReq.phoneNumber!='' && registerReq.email!='') {
        setLoading(true);
        const response: ResponseTemplate<undefined> = await registerGeneral(registerReq, 'general');
        setLoading(false);
        if (response.responseSchema.responseCode === REACT_APP_SUCCESS_CODE) {
            setSuccessHandle(true,"Hai Sobat, Registrasi Telah Berhasil ya. Jika Data Sobat Benar, maka sobat akan di email oleh BiLan untuk melakukan verifikasi.");
            setTimeout(() => {
                navigate('/');
            }, 6000);
        }else {
            setErrorHandle(true,response.responseSchema.responseMessage)
        }
    }
};



function setLocalStorage(loginResp: ResponseTemplate<LoginResp>): void {
    const isValid = isStatusResponseOk(loginResp);

    if (isValid) {
        localStorage.setItem('_accessToken_', loginResp.responseOutput.accessToken);
        localStorage.setItem('_refreshToken_', loginResp.responseOutput.refreshToken);
        localStorage.setItem('_expiredIn_', String(loginResp.responseOutput.expiresIn));
    }

}

