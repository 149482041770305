import axios from 'axios';
import {LoginResp} from "../dto/LoginResp";
import {LoginReq} from "../dto/LoginReq";
import {HEADERS_AXIOS} from "../../../../shared/constant/GlobalVariable";
import {ResponseTemplate} from "../../../../shared/dto/ResponseTemplate";
import {RegisterReq} from "../dto/RegisterReq";
import {VerifReq} from "../dto/VerifReq";

export async function callLoginUser(loginIds: string, loginReq: LoginReq): Promise<ResponseTemplate<LoginResp>> {
    var uri = process.env.REACT_APP_LOGIN_API_URL+'/general/auth/login?_ids='+loginIds;
    try {
        const response = await axios.post<ResponseTemplate<LoginResp>>(uri,loginReq,HEADERS_AXIOS);
        return response.data;
    } catch (error) {
        //TODO:Buatkan UI untuk menampilkan kesalahan ketika hit api.
        throw new Error('Unable To Called Api');
    }
}

export async function callRegister(registIds: string, registerReq: RegisterReq): Promise<ResponseTemplate<undefined>> {
    var uri = process.env.REACT_APP_LOGIN_API_URL+'/general/auth/registrasi?_ids='+registIds;
    try {
        const response = await axios.post<ResponseTemplate<undefined>>(uri,registerReq,HEADERS_AXIOS);
        return response.data;
    } catch (error) {
        //TODO:Buatkan UI untuk menampilkan kesalahan ketika hit api.
        throw new Error('Unable To Called Api');
    }
}

export async function callCaptcha(registIds: string, verifReq: VerifReq): Promise<Blob> {
    var uri = process.env.REACT_APP_LOGIN_API_URL+'/general/auth/captcha?_ids='+registIds;
    try {
        const response = await axios.post<Blob>(uri,verifReq,
            {
                ...HEADERS_AXIOS, responseType: 'blob'
            }
        );
        return response.data;
    } catch (error) {
        //TODO:Buatkan UI untuk menampilkan kesalahan ketika hit api.
        throw new Error('Unable To Called Api');
    }
}

export async function callFinalVerifRegist(verifReq:VerifReq,registIds:string): Promise<ResponseTemplate<undefined>> {
    var uri = process.env.REACT_APP_LOGIN_API_URL+'/general/auth/final-verif?_ids='+registIds;
    try {
        const response = await axios.post<ResponseTemplate<undefined>>(uri,verifReq,HEADERS_AXIOS);
        return response.data;
    } catch (error) {
        //TODO:Buatkan UI untuk menampilkan kesalahan ketika hit api.
        throw new Error('Unable To Called Api');
    }
}

export async function callBlokedVerif(verifReq:VerifReq,registIds:string): Promise<ResponseTemplate<undefined>> {
    var uri = process.env.REACT_APP_LOGIN_API_URL+'/general/auth/blocking-verif?_ids='+registIds;
    try {
        const response = await axios.post<ResponseTemplate<undefined>>(uri,verifReq,HEADERS_AXIOS);
        return response.data;
    } catch (error) {
        //TODO:Buatkan UI untuk menampilkan kesalahan ketika hit api.
        throw new Error('Unable To Called Api');
    }
}