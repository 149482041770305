import {useEffect, useState} from 'react';
import {blockedVerif, createCaptha, handleRegister, verifRegist} from "../service/AuthGeneralService";
import { useNavigate } from 'react-router-dom';
import "../../../../shared/component/css/main-style.css";
import "../css/login.css";
// @ts-ignore
import splashUp from "../logo/splash-up.png";
// @ts-ignore
import splashDown from "../logo/splash-down.png";
// @ts-ignore
import fogotPassword from "../logo/forgot-password-img.png";
// @ts-ignore
import presonRd from "../logo/person-rd.png";
// @ts-ignore
import presonGr from "../logo/person-gr.png";
// @ts-ignore
import lockGr from "../logo/lock-gr.png";
// @ts-ignore
import lockRd from "../logo/lock-rd.png";
// @ts-ignore
import eyeGr from "../logo/eye-gr.png";
// @ts-ignore
import eyeCloseGr from "../logo/eye-close-gr.png";
// @ts-ignore
import bilanLogo from "../../../../shared/component/logo/bilan-logo.png";
import Loading from "../../../../shared/component/particel/Loading";
// @ts-ignore
import warningRd from "../logo/warning-rd.png";
// @ts-ignore
import registrasi from "../logo/registrasi.png";
// @ts-ignore
import information from "../logo/information.png";
// @ts-ignore
import forgotPass from "../logo/forgot-password.png";
// @ts-ignore
import doorLogo from "../logo/door.png";
// @ts-ignore
import registrasiImg from "../logo/registrasi-img.png";
// @ts-ignore
import emailGr from "../logo/email-gr.png";
// @ts-ignore
import phoneGr from "../logo/phone-gr.png";
// @ts-ignore
import emailRd from "../logo/email-rd.png";
// @ts-ignore
import phoneRd from "../logo/phone-rd.png";
// @ts-ignore
import checklist from "../logo/checklist.png";

// @ts-ignore
import wellDone from "../logo/well-done.png";

// @ts-ignore
import lockImage from "../logo/lock-image.png";


// @ts-ignore
import ErrorMessage from "../../../../shared/component/particel/ErrorMessage";
import SuccessMessage from "../../../../shared/component/particel/SuccessMessage";
import {IDS, KEY, myUrl, paramValueOf} from "../../../../shared/component/script/UtilService";


const InitBlockedGeneralPage: React.FC = () => {
    const [verifReq, setVerifReq] = useState({ key:  paramValueOf(KEY), domain: myUrl() ,captcha:'' });
    const [isLoading, setIsLoading] = useState(false);
    const [isError,setIsError] = useState(false);
    const [isSuccess,setIsSuccess] = useState(false);
    const [messageError,setMessageError] = useState('');
    const [messageSuccess,setMessageSuccess] = useState('');
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const handleLoading=(loading:boolean)=>setIsLoading(loading);
    const handleError=(isError:boolean,messageError:string)=>{
        setIsError(isError);
        setMessageError(messageError);
    }
    const handleSuccess=(isSuccess:boolean,messageSuccess:string)=>{
        debugger;
        setIsSuccess(isSuccess);
        setMessageSuccess(messageSuccess);
    }

    const navigate = useNavigate();

    useEffect(()=>{
        const fetchData = async () => {
            const blobVal:Blob = await createCaptha(verifReq,IDS);
            if (blobVal instanceof Blob) {
                const url = URL.createObjectURL(blobVal);
                setImageSrc(url);
            } else {
                throw new Error('Expected a Blob but received something else.');
            }
        };

        fetchData();

        return () => {
            if (imageSrc) {
                URL.revokeObjectURL(imageSrc);
            }
        };
    },[]);

    return (
        <div className="container">
            <div className="content">

                <Loading isLoading={isLoading}/>

                <div className="splash-up">
                    <img className="splash-images" src={splashUp}  />
                </div>

                <div className="box-field login">
                    <div className="login-container">
                        <div className="box-obstacle">
                            <ErrorMessage isError={isError} messageError={messageError} image={warningRd}/>
                            <SuccessMessage isSuccess={isSuccess} messageSuccess={messageSuccess} image={checklist}/>

                            <div className="logo-login" >
                                <img className="logo" src={lockImage}  style={{height:'200px', width:'250px', marginBottom:'20px'}}/>
                            </div>
                            <div className="display-form-login">
                                <div className="verif-block-card">
                                    <b>Hai Hai, Sobat BiLan,</b> <br/>
                                    <span>Ada email spam menggangu yah. Tenang gangguan dapat diatasi. Isi captcha dulu yah, biar sat set bilan perbaiki gangguannya</span>
                                </div>

                                <div className="captcha-card">
                                    <div className="verif-block-card no-card">
                                        {imageSrc && <img src={imageSrc} alt="Captcha" />}
                                    </div>

                                    <div className="captcha-input">
                                        <input
                                            type="text"
                                            placeholder="Enter CAPTCHA"
                                            value={verifReq.captcha}
                                            onChange={(e) => {

                                                let val = e.target.value;
                                                if(e.target.value.length==6){
                                                    verifReq.captcha = val;
                                                    blockedVerif(verifReq,paramValueOf(IDS),navigate,handleLoading,handleError,handleSuccess);
                                                    e.target.value = '';
                                                }else {
                                                    handleError(false,'');
                                                }
                                                setVerifReq({...verifReq,captcha: e.target.value})
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


                <div className="splash-down">
                    <img className="splash-images" src={splashDown}  />
                </div>

            </div>
        </div>
    );
};

export default InitBlockedGeneralPage;