import {MouseEventHandler, useState} from 'react';
import {handleLogin, loginGeneral} from "../service/AuthGeneralService";
import { useNavigate } from 'react-router-dom';
import "../../../../shared/component/css/main-style.css";
import "../css/login.css";
// @ts-ignore
import splashUp from "../logo/splash-up.png";
// @ts-ignore
import splashDown from "../logo/splash-down.png";
// @ts-ignore
import fogotPassword from "../logo/forgot-password-img.png";
// @ts-ignore
import presonRd from "../logo/person-rd.png";
// @ts-ignore
import presonGr from "../logo/person-gr.png";
// @ts-ignore
import lockGr from "../logo/lock-gr.png";
// @ts-ignore
import lockRd from "../logo/lock-rd.png";
// @ts-ignore
import eyeGr from "../logo/eye-gr.png";
// @ts-ignore
import eyeCloseGr from "../logo/eye-close-gr.png";
// @ts-ignore
import bilanLogo from "../../../../shared/component/logo/bilan-logo.png";
import Loading from "../../../../shared/component/particel/Loading";
// @ts-ignore
import warningRd from "../logo/warning-rd.png";
// @ts-ignore
import registrasi from "../logo/registrasi.png";
// @ts-ignore
import information from "../logo/information.png";
// @ts-ignore
import forgotPass from "../logo/forgot-password.png";
// @ts-ignore
import doorLogo from "../logo/door.png";
import ErrorMessage from "../../../../shared/component/particel/ErrorMessage";


const InitForgotPasswordPage: React.FC = () => {
    const [loginReq, setLoginReq] = useState({ username: '', password: '' });
    const [isUsernameFocused, setIsUsernameFocused] = useState(false);
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError,setIsError] = useState(false);
    const [messageError,setMessageError] = useState('');
    const [isMaskedText,setIsMaskedText]=useState(true);

    const handleUserNameFocus = () => setIsUsernameFocused(true);
    const handleUserNameBlur = () => setIsUsernameFocused(false);
    const handlePasswordFocus = () => setIsPasswordFocused(true);
    const handlePasswordBlur = () => setIsPasswordFocused(false);
    const handleLoading=(loading:boolean)=>setIsLoading(loading);
    const handleError=(isError:boolean,messageError:string)=>{
        setIsError(isError);
        setMessageError(messageError);
    }
    const handleMaskedText=()=>{
        setIsMaskedText(!isMaskedText);
    }



    const navigate = useNavigate();

    return (
        <div className="container">
            <div className="content">

                <Loading isLoading={isLoading}/>

                <div className="splash-up">
                    <img className="splash-images" src={splashUp}  />
                </div>

                <div className="box-field login">
                    <div className="login-container">
                        <div className="box-obstacle">
                            <ErrorMessage isError={isError} messageError={messageError} image={warningRd}/>
                            <div className="logo-login">
                                <img className="logo" src={fogotPassword}  />
                            </div>
                            <div className="display-form-login">
                                <div className={(!isUsernameFocused && loginReq.username=='')?"container-input username":
                                    (isError?"container-input username red": "container-input username focus")}>
                                    {isError?<img src={presonRd} className="icon-logo"/>:<img src={presonGr} className="icon-logo"/>}
                                    <input
                                        autoComplete="email"
                                        className="input-form"
                                        type="text"
                                        placeholder="Username, email, atau no handphone"
                                        value={loginReq.username}
                                        onBlur={handleUserNameBlur}
                                        onFocus={handleUserNameFocus}
                                        onChange={(e) => {
                                            setIsError(false);
                                            setLoginReq({ ...loginReq, username: e.target.value })
                                        }}
                                    />
                                    { isError?
                                        <img src={warningRd} className="icon-logo-br warning"/>:''
                                    }
                                </div>



                                <div className="footer-form">
                                    <div className="contact-me"> </div>
                                    <div className="powered-by"> <span className="powered-by-span"> powered by : </span>  <img className="bilan-logo" src={bilanLogo}  /></div>
                                </div>

                                <button
                                    disabled={(loginReq.username=='')}
                                    className={(loginReq.username=='')?"button-form":
                                        isButtonActive?
                                            "button-form focus" : "button-form focus active"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleLogin(loginReq, navigate,handleLoading,handleError);
                                    }}>Lupa Password</button>

                                <div className="additional-form">
                                    <div className="registrasi" onClick={()=>navigate("/registration")}>
                                        <img src={registrasi} />
                                    </div>
                                    <div className="information">
                                        <img src={information} onClick={()=>navigate("/information")}/>
                                    </div>
                                    <div className="lupa-password">
                                        <img src={doorLogo} onClick={()=>navigate("/init")}/>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


                <div className="splash-down">
                    <img className="splash-images" src={splashDown}  />
                </div>

            </div>
        </div>
    );
};

export default InitForgotPasswordPage;