import {useEffect} from "react";
import "../css/loading.css"

interface LoadingSpinnerProps {
    isLoading: boolean;
}

const Loading : React.FC<LoadingSpinnerProps> = ({ isLoading }) => {
    return (
      <div>
          {isLoading?
           <div className="container-loading">
              <div className="spinner-container">
                  <div className="spinner"></div>
              </div>
          </div>:<div></div>}

      </div>

    );
};

export default Loading;