import './App.css';
import {Navigate} from 'react-router-dom';
import {BrowserRouter,Routes, Route} from "react-router-dom";
import ProtectedRoute from "./configuration/router/ProtectedRoute";
import NotFound from "./shared/component/page/NotFoundPage";
import InitLoginGeneralPage from "./module/login/general/page/InitLoginGeneralPage";
import InitDashboardGeneralPage from "./module/login/dashboard/page/InitDashboardGeneralPage";
import InitForgotPasswordPage from "./module/login/general/page/InitForgotPasswordPage";
import InitRegistrasiGeneralPage from "./module/login/general/page/InitRegistrasiGeneralPage";
import InitInformasiPage from "./module/login/general/page/InitInformasiPage";
import InitVerifGeneralPage from "./module/login/general/page/InitVerifGeneralPage";
import InitBlockedGeneralPage from "./module/login/general/page/InitBlockedGeneralPage";
function App() {
  return (
      <BrowserRouter>
        <Routes>
            <Route path="/init" element={<InitLoginGeneralPage/>} />
            <Route path="/forgot-password" element={<InitForgotPasswordPage/>} />
            <Route path="/registration" element={<InitRegistrasiGeneralPage/>} />
            <Route path="/information" element={<InitInformasiPage/>} />
            <Route path="/verif" element={<InitVerifGeneralPage/>} />
            <Route path="/blocked" element={<InitBlockedGeneralPage/>} />
            <Route path="/" element={<Navigate to="/init" />} />
            <Route
                path="/dashboard"
                element={
                    <ProtectedRoute>
                        <InitDashboardGeneralPage />
                    </ProtectedRoute>
                }
            />


            {/* Route untuk halaman 404 */}
            <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
