import {Navigate} from 'react-router-dom';
import React from 'react';

const isAuthenticated = () => {
    const accessToken = localStorage.getItem('_accessToken_');
    const refreshToken = localStorage.getItem('_refreshToken_');
    const expiredIn = localStorage.getItem('_expiredIn_');
    return !!accessToken;
};

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute:React.FC<ProtectedRouteProps> =  ({ children}) => {
    if (isAuthenticated()) {
        return <>{children}</>;
    } else {
        return <Navigate to="/init" />;
    }
};

export default ProtectedRoute;
