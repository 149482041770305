import "../css/errorLoading.css";

interface LoadingSpinnerProps {
    isSuccess: boolean;
    messageSuccess:string;
    image:string;
}

const ErrorMessage : React.FC<LoadingSpinnerProps> = ({ image,isSuccess,messageSuccess }) => {
    return (
        <div>
            {
                isSuccess?
                    <div className="message-box success-box in">
                        <img src={image} className="icon-logo-br warning"/>
                        <span className="value-span">{messageSuccess}.</span>
                    </div>:
                    <div className="message-box success-box out">
                        <img src={image} className="icon-logo-br warning"/>
                        <span className="value-span">{messageSuccess}.</span>
                    </div>
            }
        </div>

    );
};

export default ErrorMessage;